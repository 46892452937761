import React, { useCallback, useEffect, useRef, useState } from 'react';
import UnityRendererV7, { UnityContent } from 'react-unity-webgl-v7';
import UnityRenderer, { UnityContext } from 'react-unity-webgl';
import { isMobile, isIOS } from 'react-device-detect';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import jwt from 'jsonwebtoken';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const Game = () => {
  const { slug } = useRouteMatch().params;
  const [gameData, setGameData] = useState(null);
  const [unityContext, setUnityContext] = useState(null);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const [isLoading, setIsLoading] = useState(true);
  const [progression, setProgression] = useState(0);
  const container = useRef(null);
  const loading = useRef(null);
  const [cookies] = useCookies([`license-${slug}`]);
  const history = useHistory();
  const [room, setRoom] = useState('');
  const [role, setRole] = useState('');

	 
  const updateDimensions = useCallback(() => {
    const canvasWidth = gameData.canvasWidth || 1440;
    const canvasHeight = gameData.canvasHeight || 860;
    const w = container.current.parentNode.clientWidth;
    const h = container.current.parentNode.clientHeight;
    if (w / h > canvasWidth / canvasHeight) {
      setHeight('100%');
      setWidth(h * canvasWidth / canvasHeight);
    } else {
      setWidth('100%');
      setHeight(w * canvasHeight / canvasWidth);
    }
  }, [container, gameData, setWidth, setHeight]);

  const updateLoading = useCallback(p => {
console.log('upateloading');	  
    if (!isLoading) {
      return;
    }
    setProgression(p);
    if (loading.current !== null && p >= 1) {
      loading.current.classList.add('fade-out');
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [loading, isLoading, setProgression, setIsLoading]);

  const onPlayerReady = useCallback(() => {
    unityContext.send(
      'WebgameManager',
      'InitWebgame',
      JSON.stringify({ room, role, server: process.env.REACT_APP_GAME_SERVER_URI, admin: !!role && role.indexOf('animator') > -1, })
    );
    unityContext.send('WebgameManager', 'SetIsMobile', isMobile ? 'True' : 'False');
    unityContext.send('WebgameManager', 'SetIsIOS', isIOS ? 'True' : 'False');

  }, [unityContext, room, role]);
  
  const onDecoPlayer = () => { history.push("/"); }
	  
  useEffect(() => {
    if (unityContext) {
      unityContext.on('PlayerReady', onPlayerReady);
      unityContext.on('progress', updateLoading);
	  unityContext.on('DecoPlayer', onDecoPlayer);
    }
  }, [unityContext, setProgression, updateLoading, onPlayerReady]);

  useEffect(() => {
    if (!gameData) {
      return;
    }
    if (gameData.unityPre2020) {
      const path = gameData.buildPath.split('/');
      const folderPath = path.slice(0, path.length - 1).join('/'); // Remove the build file name from the path, to get only the folder path
      console.log("path " + path.slice(0, path.length - 1).join('/'));
      setUnityContext(new UnityContent(
        `${gameData.buildPath}.json`,
        `${folderPath}/UnityLoader.js`,
      ));
    } else {
      setUnityContext(new UnityContext({
        loaderUrl: `${gameData.buildPath}.loader.js`,
        dataUrl: `${gameData.buildPath}.data${gameData.buildCompression ? `.${gameData.buildCompression}` : ''}`,
        frameworkUrl: `${gameData.buildPath}.framework.js${gameData.buildCompression ? `.${gameData.buildCompression}` : ''}`,
        codeUrl: `${gameData.buildPath}.wasm${gameData.buildCompression ? `.${gameData.buildCompression}` : ''}`,
      }));
    } 
  }, [gameData, setUnityContext]);

  /**
   * Handle window resize
   */

  useEffect(() => {
    // Only resize if the game has a specific canvas width or height
    if (container.current && gameData && (!!gameData.canvasWidth || !!gameData.canvasHeight)) {
      updateDimensions();
      window.addEventListener('resize', updateDimensions, false);
      return () => {
        window.removeEventListener('resize', updateDimensions, false);
      };
    }
  }, [gameData, container, setWidth, setHeight, updateDimensions]);

  /**
   * Fetch the game data
   */
  useEffect(() => {
    axios.get(`${API_URL}/games/${slug}`)
      .then(res => setGameData(res.data))
      .catch(err => console.error(err));
  }, [slug, setGameData]);

  useEffect(() => {
    if (!cookies[`license-${slug}`]) {
      // Redirect to home
      history.push('/');
      return;
    }
    const license = jwt.decode(cookies[`license-${slug}`], SECRET);
    setRoom(license.room);
    setRole(license.role);
  }, [cookies, slug, history, setRoom, setRole]);
	
  return (
    <>
    <div className='flex justify-center align-center min-h-screen w-full'  >
      { gameData && <div ref={container} style={{ width:'100%', height:'95%' }} className='canvasContainer' >
        {
          !!gameData.unityPre2020
          ? unityContext && <UnityRendererV7 unityContent={unityContext} style={{ width:'75%', height:'100%' }} />
          : unityContext && <UnityRenderer unityContext={unityContext}  style={{ width:'75%', height:'100%' }} />
        }
        {
          isLoading &&
          <div ref={loading} className='d-flex flex-column justify-content-center align-items-center bg-light position-absolute left-0' style={styles.loading}>
            <p className='mt-3 text-black' style={{ fontSize: 30 }}>{`${(progression * 100).toFixed()} %`}</p>
          </div>
        }
      </div>}
    </div>
	</>
  );
}

export default Game;
const styles = {
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
