import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

const CloseSession = props => {
  const { close, onSuccess, authToken, session } = props;
  const [t] = useTranslation();

  const closeSession = useCallback(async () => {
    try {
      // Close session
      await axios.post(`${API_URL}/sessions/close/${session.id}`, {}, { headers: { 'x-auth-token': authToken } });
      onSuccess(session.id);
    } catch (error) {
      console.log(error.response)
    }
  }, [session, onSuccess, authToken]);

  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        {/* Trick to align the modal */}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>

        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' role='dialog' aria-modal='true' aria-labelledby='modal-headline'>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mt-3 text-center w-full sm:mt-0 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>{t('Close session')}</h3>
                <h4 className='mt-2 text-sm font-medium text-gray-900'>{t('Do you really want to close session')}{' '}"{session.name}"?</h4>
              </div>
            </div>
          </div>
          <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
            <button type='button' onClick={closeSession} className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'>{t('Close session')}</button>
            <button type='button' onClick={close} className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>{t('Cancel')}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CloseSession;
CloseSession.propTypes = {
  authToken: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
}