import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import app_fr from 'translations/app-fr.json';
import app_en from 'translations/app-en.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: 'en',
    defaultNS: 'app',
    react: { useSuspense: false },
    resources: {
      fr: { 'app': app_fr, },
      en: { 'app': app_en, },
    }
  });

export default i18n;