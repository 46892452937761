import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

const CreateSession = props => {
  const { close, onSuccess, authToken } = props;
  const [t] = useTranslation();
  const [games, setGames] = useState([])

  const createSession = useCallback(async e => {
    e.nativeEvent.stopPropagation();
    e.nativeEvent.preventDefault()
    const { name: { value: name }, game: { value: game } } = e.target

    try {
      // Create new session
      const res = await axios.post(`${API_URL}/sessions`, { game, name }, { headers: { 'x-auth-token': authToken } });
      const { session } = res.data;
      onSuccess(session);
    } catch (error) {
      console.log(error.response)
    }
  }, [onSuccess, authToken]);

  useEffect(() => {
    axios.get(`${API_URL}/games`)
      .then(res => setGames(res.data))
      .catch(err => console.error(err));
  }, [setGames])

  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        {/* Trick to align the modal */}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>

        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' role='dialog' aria-modal='true' aria-labelledby='modal-headline'>
          <form action='' onSubmit={createSession}>
            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div className='sm:flex sm:items-start'>
                <div className='mt-3 text-center w-full sm:mt-0 sm:text-left'>
                  <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>{t('Create session')}</h3>
                  <div className='mt-2'>

                    <div className='col-span-6'>
                      <label htmlFor='session-name' className='block text-sm font-medium text-gray-700'>{t('Session name')} ({t('Optional')})</label>
                      <input type='text' name='name' id='session-name' autoComplete='name' className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md' />
                    </div>

                    <div className='col-span-6 sm:col-span-3 mt-3'>
                      <label htmlFor='game' className='block text-sm font-medium text-gray-700'>{t('Game')}</label>
                      <select id='game' name='game' autoComplete='game' className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'>
                        { games.map(g => <option key={g._id} value={g.slug}>{g.name}</option>) }
                      </select>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
              <button type='submit' className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'>{t('Validate')}</button>
              <button type='button' onClick={close} className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>{t('Cancel')}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateSession;
CreateSession.propTypes = {
  authToken: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}