import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';

import 'index.css';
import App from 'App';

function adjustVh() {
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener('ready', adjustVh);
window.addEventListener('resize', adjustVh);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
