import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

const CreatePassword = props => {
  const { close, onSuccess, authToken, sessionID } = props;
  const [t] = useTranslation();
  const [requesting, setRequesting] = useState(false);

  const createPwd = useCallback(async e => {
    e.nativeEvent.stopPropagation();
    e.nativeEvent.preventDefault()
    const form = e.target;
    const nbPasswords = form['nb-passwords'].value;
    const allowMultiple = form['allow-multiple'].checked;
    const role = form.role.value || 'participant';
    const expires = form.duration.value === -1 ? 0 : form.duration.value * 60;

    try {
      setRequesting(true);
      const res = await axios.post(`${API_URL}/passwords`, { passwords: nbPasswords, expires, session: sessionID, role, allowMultiple }, { headers: { 'x-auth-token': authToken } });
      onSuccess(res.data.docs.ops);
    } catch (error) {
      console.error(error)
    }
    setRequesting(false);
  }, [onSuccess, authToken, sessionID, setRequesting]);

  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        {/* Trick to align the modal */}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>

        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' role='dialog' aria-modal='true' aria-labelledby='modal-headline'>
          <form action='' onSubmit={createPwd}>
            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div className='sm:flex sm:items-start'>
                <div className='mt-3 text-center w-full sm:mt-0 sm:text-left'>
                  <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>{t('Create passwords')}</h3>
                  <h4 className='mt-2 text-sm font-medium text-gray-500'>{t('Add one or more passwords for this session')}</h4>
                  <div className='mt-2 flex flex-col sm:flex-row'>
                    <div className='mt-1 flex rounded-md shadow-sm sm:mr-4'>
                      <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>{t('Quantity')}</span>
                      <input type='number' name='nb-passwords' id='nb-passwords' defaultValue={1} className='focus:ring-primary focus:border-primary flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300' />
                    </div>
                    <div className='mt-1 flex rounded-md shadow-sm w-full'>
                      <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>{t('Validity')}</span>
                      <select name='duration' id='duration' autoComplete='duration' className='focus:ring-primary focus:border-primary flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'>
                        <option value={0.5}>00:30</option>
                        <option value={1}>01:00</option>
                        <option value={1.5}>01:30</option>
                        <option value={2}>02:00</option>
                        <option value={2.5}>02:30</option>
                        <option value={3}>03:00</option>
                        <option value={-1}>{t('Unlimited')}</option>
                      </select>
                    </div>
                  </div>
                  <div className='mt-4 flex rounded-md shadow-sm w-full'>
                      <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>{t('Role')}</span>
                      <select name='role' id='role' className='focus:ring-primary focus:border-primary flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'>
                        <option value={'participant'}>{t('Participant')}</option>
                        <option value={'animator'}>{t('Animator')}</option>
                      </select>
                    </div>
                  <div className='mt-4 flex items-start'>
                    <div className='flex items-center h-5'>
                      <input id='allow-multiple' name='allow-multiple' type='checkbox' defaultChecked={true} className='focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded' />
                    </div>
                    <div className='ml-3 text-sm'>
                      <label htmlFor='allow-multiple' className='font-medium text-gray-700'>{t('Allow multiple uses')}</label>
                      <p className='text-gray-500'>{t('Users can use the same password multiple times')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
              <button type='submit' disabled={requesting} className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-700 sm:ml-3 sm:w-auto sm:text-sm'>{t('Validate')}</button>
              <button type='button' onClick={close} className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>{t('Cancel')}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreatePassword;
CreatePassword.propTypes = {
  authToken: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  sessionID: PropTypes.string.isRequired,
}