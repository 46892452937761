import React, { useEffect, useState, useCallback } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { computedMatch: { params } } = rest;
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [cookies,, removeCookie] = useCookies([`license-${params.slug}`]);

  const authenticateIfRoomActive = useCallback(async (id, token) => {
    if (!id || !token) {
      setIsAuthenticated(false)
      return;
    }
    try {
      const res = await axios.get(`${API_URL}/sessions/${id}`, { headers: { 'x-auth-token': token } });
      const { data: session } = res;
      if (!session.active) {
        removeCookie(`license-${params.slug}`);
      }
      setIsAuthenticated(session.active)
    } catch (error) {
      setIsAuthenticated(false)
    }
  }, [params, setIsAuthenticated, removeCookie])

  useEffect(() => {
    const license = cookies[`license-${params.slug}`];
    if (license) {
      try {
        const credentials = jwt.decode(license, SECRET);
        const { exp, game, room } = credentials;
        const dateNow = new Date();
        if (!room || game !== params.slug || (!!exp && exp < dateNow.getTime() / 1000)) {
          removeCookie(`license-${params.slug}`);
          setIsAuthenticated(false);
        } else {
          authenticateIfRoomActive(room, license);
        }
      } catch (error) {
        console.log(error);
        removeCookie(`license-${params.slug}`);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [params, authenticateIfRoomActive, setIsAuthenticated, cookies, removeCookie]);

  if (isAuthenticated === null) {
    return null;
  }

  return <Route {...rest} render={props => !isAuthenticated ? <Redirect to='/' /> : <Component {...props} />} />;
};

export default PrivateRoute;