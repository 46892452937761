import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import useInterval from '@use-it/interval';
import axios from 'axios';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';

import CreateSession from 'components/CreateSession';
import CloseSession from 'components/CloseSession';
import CreatePassword from 'components/CreatePassword';
import PasswordRow from 'components/PasswordRow';
import { getStatus, formatTime } from 'utils';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;
const REFRESH_DELAY = 5000;

const Dashboard = () => {
  const [t] = useTranslation();
  const [cookies, , removeCookie] = useCookies([`token`]);
  const [passwords, setPasswords] = useState([]);
  const [addingPwd, setAddingPwd] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [addingSession, setAddingSession] = useState(false);
  const [csv, setCsv] = useState(null);
  const [closingSession, setClosingSession] = useState('');
  const [activeTab, setActiveTab] = useState('');
  const [sessions, setSessions] = useState([]);
  const [archivedSessions, setArchivedSessions] = useState([]);

  const { token } = cookies;

  const logout = useCallback(() => {
    removeCookie('token');
    window.location.reload();
  }, [removeCookie]);

  const onTabClick = useCallback(e => {
    setActiveTab(e.target.dataset.id);
  }, [setActiveTab]);

  /** Checkboxes */
  const updateCsv = useCallback(() => {
    const allCheckboxes = Array.from(document.querySelectorAll('.pwd-checkbox'));
    const checkboxes = allCheckboxes.filter(c => c.checked);
    if (checkboxes.length === 0) {
      setAllChecked(false);
      setCsv(null)
      return;
    }
    const toExport = passwords.filter((_, i) => checkboxes.some(checkbox => parseInt(checkbox.dataset.id) === i));
    const csvData = [
      [t('Password'), t('Status'), t('Validity'),t('Activated'), t('Expires')],
      ...toExport.map(pwd => [
        pwd.value,
        t(getStatus(pwd.status)),
        formatTime(pwd.expires),
        pwd.date ? (new Date(pwd.date).toLocaleString()) : '-',
        (pwd.date && pwd.expires > 0) ? `${(new Date(pwd.date).getTime() + pwd.expires * 60000).toLocaleString()}` : '-'
      ])
    ]
    setCsv(csvData)
  }, [t, passwords, setCsv])

  const toggleCheckbox = useCallback(i => {
    const checkbox = document.getElementById(`checkbox-${i}`);
    if (!checkbox) {
      return;
    }
    checkbox.checked = !checkbox.checked;

    const allCheckboxes = Array.from(document.querySelectorAll('.pwd-checkbox'));
    const checkboxes = allCheckboxes.filter(c => c.checked);
    if (checkboxes.length === 0) {
      setAllChecked(false);
      setCsv(null)
      return;
    }
    setAllChecked(allCheckboxes.length === checkboxes.length);
    updateCsv();
  }, [setCsv, setAllChecked, updateCsv]);

  const toggleAllCheckboxes = useCallback(() => {
    const checkboxes = Array.from(document.querySelectorAll('.pwd-checkbox'));
    if (allChecked) {
      checkboxes.forEach(c => c.checked = false);
      setAllChecked(false);
    } else {
      checkboxes.forEach(c => c.checked = true);
      setAllChecked(true);
    }
    updateCsv();
  }, [allChecked, setAllChecked, updateCsv]);

  /** Passwords */
  const onPasswordsCreated = useCallback(async newPasswords => {
    setSessions(sessions.map(sess => sess.id === activeTab ? { ...sess, passwords: [...sess.passwords, ...newPasswords] } : sess))
    setAddingPwd(false);
  }, [activeTab, setSessions, sessions, setAddingPwd]);

  const onDeletePassword = useCallback(async id => {
    // If the checkbox was checked, uncheck it
    if (document.getElementById(`checkbox-${id}`).checked && csv && csv.length <= 2) {
      setCsv(null);
    }

    setSessions(sessions.map(sess => sess.id === activeTab ? { ...sess, passwords: sess.passwords.filter(pwd => pwd._id !== id) } : sess))
  }, [activeTab, sessions, setSessions, csv, setCsv]);

  /** Sessions */
  const onSessionCreated = useCallback(session => {
    setSessions([...sessions, session])
    setAddingSession(false);
  }, [sessions, setSessions, setAddingSession])

  const onSessionClosed = useCallback(id => {
    const session = sessions.find(sess => sess.id === id);
    session.active = false;
    const newSessions = sessions.filter(sess => sess.id !== id);
    if (activeTab === id) {
      setActiveTab(newSessions.length > 0 ? newSessions[0].id : '')
    }
    setClosingSession('');
    setSessions(newSessions.filter(sess => sess.id !== id));
    setArchivedSessions([...archivedSessions, session]);
  }, [sessions, setSessions, activeTab, archivedSessions])

  const fetchSessions = useCallback(async (token) => {
    try {
      const res = await axios.get(`${API_URL}/sessions`, { headers: { 'x-auth-token': token } })
      const { data } = res;
      const { sessions } = data;
      setSessions(sessions.filter(sess => sess.active === true))
    } catch (error) {
      console.log(error, error.response);
    }
  }, [setSessions]);

  useEffect(() => {
    if (!token) {
      return window.location.reload();
    }

    // Fetch the sessions
    fetchSessions(token);
  }, [token, setSessions, fetchSessions]);

  /** Once the token was stored, check for updates periodically */
  useInterval(() => token && fetchSessions(token), REFRESH_DELAY);

  /** Update tab contents (passwords displayed) */
  useEffect(() => {
    if (activeTab === '' && sessions.length > 0) {
      setActiveTab(sessions[0].id)
      setPasswords(sessions[0].passwords)
    }
    if (activeTab && sessions.some(sess => sess.id === activeTab)) {
      setPasswords(sessions.find(sess => sess.id === activeTab).passwords)
    }
  }, [activeTab, setPasswords, setActiveTab, sessions]);

  /** Reset CSV when changing tabs */
  useEffect(() => { setCsv(null); }, [activeTab, setCsv]);

  return (
    <>
      <div className='min-h-screen w-full flex flex-col items-stretch justify-start bg-gray-50 pb-12'>
        <nav className='bg-gray-800 w-full'>
          <div className='w-full px-4 sm:px-6 lg:px-8'>
            <div className='flex items-center justify-between h-16'>
              <div className='flex items-center'>
                <span className='text-white font-bold'>{t(process.env.REACT_APP_NAME)}</span>
              </div>
              <div className=''>
                <div className='ml-4 flex items-center md:ml-6'>
                  <button className='bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium' onClick={logout}>{t('Logout')}</button>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <header className='bg-white w-full'>
          <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 xl:px-0 flex justify-between'>
            <h1 className='text-3xl font-bold leading-tight text-gray-900'>{t('Sessions')}</h1>
            <button className='py-2 px-4 ml-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary' onClick={() => setAddingSession(true)}>{t('Create session')}</button>
          </div>
        </header>
        <main className='w-full'>
          <div className='bg-white shadow w-full min-h-1'>
            {
              sessions.length > 0 &&
              <ul className='flex flex-wrap list-style-none mx-auto sm:max-w-7xl'>
                {sessions.map(sess => <li key={sess.id} className='flex'><span title={sess.name} onClick={onTabClick} data-id={sess.id} className={classnames('p-3 cursor-pointer border-primary text-sm text-gray-900', { 'border-b-2': activeTab === sess.id })}>{sess.name}</span></li>)}
              </ul>
            }
          </div>
          <div className='w-full mx-auto sm:max-w-7xl'>
            <div className='flex justify-between mt-3 mb-3'>
              <span>
                {activeTab && <button className='mr-2 px-3 py-2 bg-primary rounded font-bold text-white text-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary' onClick={() => setAddingPwd(true)}>+{' '}{t('Create passwords')}</button>}
                {activeTab && (csv !== null) && <CSVLink className='px-3 py-2 bg-gray-800 rounded font-bold text-white text-sm hover:bg-gray-800-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800' filename={`Mots_de_passe-${activeTab}.csv`} data={csv}>{t('Export')}</CSVLink>}
              </span>
              <span>
                <button className='px-3 py-2 bg-red-600 rounded font-bold text-white text-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700' onClick={() => setClosingSession(activeTab)}>{t('Close session')}</button>
              </span>
            </div>

            <table className='min-w-full max-w-full divide-y divide-gray-200 mt-3 table-auto'>
              <thead className='bg-gray-50'>
                <tr>
                  <th scope='col' className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'><input checked={allChecked} onChange={toggleAllCheckboxes} type='checkbox' /></th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Password')}</th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Status')}</th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Validity')}</th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Activated')}</th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Expires')}</th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Activations')}</th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Role')}</th>
                  <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>{t('Actions')}</th>
                </tr>
              </thead>
              <tbody className='bg-white max-w-full divide-y divide-gray-200'>
                {passwords.length > 0 && passwords.map((pwd, i) => <PasswordRow key={pwd._id} pwd={pwd} authToken={token} onClick={() => toggleCheckbox(pwd._id)} onUpdate={() => fetchSessions(token)} onDelete={onDeletePassword} />)}
              </tbody>
            </table>
          </div>
        </main>
      </div>
      {addingPwd && <CreatePassword authToken={token} sessionID={activeTab} close={() => setAddingPwd(false)} onSuccess={onPasswordsCreated} />}
      {addingSession && <CreateSession authToken={token} close={() => setAddingSession(false)} onSuccess={onSessionCreated} />}
      {closingSession !== '' && <CloseSession authToken={token} session={sessions.find(sess => sess.id === closingSession)} close={() => setClosingSession('')} onSuccess={onSessionClosed} />}
    </>
  )
}

export default Dashboard
