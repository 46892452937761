import { useState } from 'react';
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom';
import 'i18n';

import 'App.css';
import Home from 'components/Home';
import Admin from 'components/Admin';
import Game from 'components/Game';
import PrivateRoute from 'components/PrivateRoute';
import LegalNotice from 'components/LegalNotice';


function App() {
	const [showInfo, setShowInfo] = useState(false);
  return (
    <div className='flex min-h-screen'>
      <BrowserRouter basename="/webgame/build">
        <Switch>
          <Route exact path='/admin' component={Admin} />
          <PrivateRoute exact path='/:slug' component={Game} />
          <Route exact path='/' component={Home} />
          <Redirect from='*' to='/' />
        </Switch>
      </BrowserRouter>
	  <footer onClick={() => setShowInfo(true)}>Information sur la protection des données personnelles</footer>
      { showInfo && <LegalNotice onClose={() => setShowInfo(false)} />}
    </div>
  );
}

export default App;
